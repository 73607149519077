import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const SingleImage = makeShortcode("SingleImage");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "clinical-anxiety-what-should-i-look-for"
    }}>{`Clinical anxiety: What should I look for?`}</h1>
    <TextCustom variant="h5" customColor="blue" paragraph mdxType="TextCustom">
  What is clinical anxiety?
    </TextCustom>
    <p>{`Everybody experiences anxiety at times. When people describe their anxiety, they may use terms such as: anxious, stressed, nervous, on edge, worried, tense or scared. Although anxiety is an unpleasant state, it can be quite useful in helping a person to avoid dangerous situations and motivate them to solve everyday problems. However, anxiety can become a problem if it is severe, long-lasting and interferes with a person’s life. `}<em parentName="p">{`Clinical anxiety`}</em>{` refers to symptoms of constantly feeling nervous, anxious or on edge, and not being able to stop or control worrying. The anxiety is strong enough to cause trouble at school or work, in personal relationships (e.g. with family, friends) or to make it difficult to get on with day-to-day activities.`}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m9/42-01.svg" alt="child on stairs" mdxType="SingleImage" />
    <p>{`At home, a teenager with clinical anxiety may:`}</p>
    <ul>
      <li parentName="ul">{`Complain of headaches and other physical problems to avoid going to school.`}</li>
      <li parentName="ul">{`Be tearful in the morning, saying they don’t want to go to school.`}</li>
      <li parentName="ul">{`Spend excessive time doing homework or express unnecessary concerns that the work isn’t good enough.`}</li>
      <li parentName="ul">{`Demand constant reassurance from parents.`}</li>
      <li parentName="ul">{`Be touchy and irritable in interactions with family members.`}</li>
      <li parentName="ul">{`Spend a lot of time getting ready for social occasions, worrying about their appearance or what they might do, or decide at the last minute not to attend a social occasion.`}</li>
      <li parentName="ul">{`Wash their hands repeatedly, even though they appear clean.`}</li>
    </ul>
    <p>{`You can find out more about what to do to help someone with clinical anxiety by downloading the `}<GoTo external to="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_child_guidelines_A4_2012_1.pdf" mdxType="GoTo">{`Mental Health First Aid guidelines for child trauma`}</GoTo>{` and `}<GoTo external to="https://mhfa.com.au/sites/mhfa.com.au/files/MHFA_panic_guidelines_A4_2012.pdf" mdxType="GoTo">{`panic attacks`}</GoTo>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      